import { useCallback, useEffect, useMemo, useState } from "react";
import type { ChangeEvent } from "react";
import { Range, Root, Thumb, Track } from "@radix-ui/react-slider";
import { t } from "@/i18n-js/instance";
import { classNames } from "@/react/helpers/twMergeWithCN";
import { Icon } from "../Icon";
import { extractValueAndUnit, mergeValueAndUnit } from "./utils";

export interface SliderWithInputProps {
  value?: string;
  onChange?: (value: string) => void;
  min?: number;
  max?: number;
  step?: number;
  className?: string;
  inputClassName?: string;
  defaultUnit?: string;
  units?: ReadonlyArray<string>;
}

export function SliderWithInput({
  value: controlledValue,
  onChange,
  min = 0,
  max: maxProp = 100,
  step = 0.1,
  className = "",
  inputClassName = "w-fit",
  defaultUnit,
  units = [],
}: Readonly<SliderWithInputProps>) {
  const { value: extractedValue, unit: extractedUnit } = useMemo(
    () => extractValueAndUnit(controlledValue),
    [controlledValue],
  );
  const initialValue = extractedValue !== undefined ? extractedValue : min;
  const initialUnit = extractedUnit ?? defaultUnit ?? units[0] ?? "";

  const [internalValue, setInternalValue] = useState<number>(initialValue);
  const [internalUnit, setInternalUnit] = useState<string>(initialUnit);
  const [max, setMax] = useState<number>(maxProp);

  useEffect(() => {
    const { value: newValue, unit: newUnit } =
      extractValueAndUnit(controlledValue);
    if (newValue !== undefined && newValue !== internalValue) {
      setInternalValue(newValue);
    }
    if (newUnit !== undefined && newUnit !== internalUnit) {
      setInternalUnit(newUnit ?? defaultUnit ?? units[0] ?? "");
    }
  }, [controlledValue, defaultUnit, internalUnit, internalValue, units]);

  const shouldShowUnitSelector = units.length > 0 || !!defaultUnit;

  const mergeValueAndInvokeOnChange = useCallback(
    (value: number, unit: string) => {
      const mergedValue = mergeValueAndUnit(value, unit);
      onChange?.(mergedValue);
    },
    [onChange],
  );

  const handleSliderChange = useCallback(
    (newValue: number[]) => {
      const updatedValue = newValue[0];
      setInternalValue(updatedValue);
      mergeValueAndInvokeOnChange(updatedValue, internalUnit);
    },
    [internalUnit, mergeValueAndInvokeOnChange],
  );

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.valueAsNumber;
    if (!isNaN(newValue)) {
      const boundedValue = Math.min(Math.max(newValue, min), max);
      setInternalValue(boundedValue);
      mergeValueAndInvokeOnChange(boundedValue, internalUnit);
    }
  };

  const handleUnitChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedUnit = event.target.value;
    setInternalUnit(selectedUnit);
    mergeValueAndInvokeOnChange(internalValue, selectedUnit);
  };

  useEffect(() => {
    const newMax = internalUnit === "%" ? 100 : maxProp;
    setMax(newMax);
    if (internalValue > newMax) {
      handleSliderChange([newMax]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- internalValue omitted to prevent re-render cycles when capping values
  }, [internalUnit, maxProp, handleSliderChange]);

  return (
    <div
      className={classNames(
        "flex w-full max-w-md items-center space-x-4",
        className,
      )}
    >
      <Root
        className="relative flex h-6 w-full cursor-pointer items-center"
        value={[internalValue]}
        onValueChange={handleSliderChange}
        max={max}
        min={min}
        step={step}
      >
        <Track className="bg-disabled relative h-1 w-full rounded">
          <Range className="bg-dark-primary-button absolute h-full rounded" />
        </Track>
        <Thumb
          className="bg-dark-primary-button block h-4 w-4 rounded-full shadow-md"
          aria-label={t("slider.slider_thumb_aria_label")}
        />
      </Root>

      <div className="group flex items-center">
        <input
          type="number"
          value={internalValue}
          onChange={handleInputChange}
          className={classNames(
            inputClassName,
            "border-primary bg-primary text-dark h-8 border px-2 py-1 text-center [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none",
            "focus:border-darkest group-focus-within:border-darkest focus:!shadow-none focus:!outline-none focus:!ring-0",
            "group-hover:border-darkest",
            shouldShowUnitSelector ? "rounded-l-lg border-r-0" : "rounded-lg",
          )}
          min={min}
          max={max}
          step={step}
        />

        {shouldShowUnitSelector && (
          <div className="relative -ml-px">
            <select
              value={internalUnit}
              onChange={handleUnitChange}
              className={classNames(
                inputClassName,
                "border-primary bg-primary text-dark group-hover:border-darkest focus:border-darkest group-focus-within:border-darkest h-8 appearance-none rounded-l-none rounded-r-lg border bg-none px-2 py-1 pr-6 text-center focus:!outline-none focus:!ring-0",
              )}
            >
              {units.length > 0 ? (
                units.map(unitOption => (
                  <option key={unitOption} value={unitOption}>
                    {unitOption}
                  </option>
                ))
              ) : defaultUnit ? (
                <option value={defaultUnit}>{defaultUnit}</option>
              ) : null}
            </select>
            <div className="pointer-events-none absolute right-2 top-1/2 flex -translate-y-1/2">
              <Icon type="12-chevron-down" size={12} className="text-default" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
